import BaseRequest from './base_request';

class CustomerPendingFeeRequest extends BaseRequest {

    clearPendingFee(id, data) {
        return this.performRequest(BaseRequest.METHOD_POST, `${ id }`, data);
    }

}

export default new CustomerPendingFeeRequest('customerPendingFee');