
export default {
    SET_REPORTS(state, entities) {
        state.entities = entities;
    },
    SET_OPERATIONS(state, operations) {
        state.operations = operations;
    },
    SET_CUSTOMERS(state, customers) {
        state.customers = customers;
    },
    SET_BRANCHES(state, branches) {
        state.branches = branches;
    },
    SET_TOTAL_PENDING(state, data) {
        state.totalPending = data;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    }
}